html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* padding-right: 0 !important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    96.34deg,
    #fcfcfc 8.92%,
    #fcfcfc 8.93%,
    #f8faff 100%
  );
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blurry-bg {
  filter: blur(5px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  display: none;
  z-index: 10;
}


div [class^="MuiPickersCalendar-week"] button[class*="MuiPickersDay-daySelected"] 
{
    background: #4001C5;

}
div [class^="MuiPickersCalendar-week"] button[class*="MuiPickersDay-daySelected"]:hover 
{
    background: #4001C5;

}
  div [class^="MuiPickersCalendarHeader-switchHeader"] p[class*=" MuiTypography-body1"]{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    
    text-align: center;
  
    
    color: #3F3B3B;
}
div[class^="MuiPickersCalendarHeader-daysHeader"] span[class *="MuiPickersCalendarHeader-dayLabel"]{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3F3B3B;
}
div [class^="MuiPickersCalendar-week"] button[class*="MuiPickersDay-day"] 
span[class *="MuiIconButton-label"] p[class*="MuiTypography-body2"]{
    position: absolute;
    height: 24px;
    left: 0%;
    right: 0%;
    top: calc(50% - 24px/2 + 1px);
    
    /* Caption/14px/Regular */
    
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    
    text-align: center;
    
    /* Black/700 (Base) */
    
      
    }


div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersToolbar"] {
  background: transparent;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersToolbarText-toolbarTxt"] {
  color: black;
  padding: 8px;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClock-container"] {
  margin: 0px;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClock-pin"] {
  background: #4001c5;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClockPointer-pointer"] {
  background: #4001c5;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClockPointer-thumb"] {
  border: 14px solid #4001c5;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClockPointer-noPoint"] {
  background: #4001c5;
}

div[class^="MuiPickersTimePickerToolbar"] h2[class*="MuiPickersToolbarText-toolbarBtnSelected"] {
  background: #fff;
  font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.7px;
   border: 1px solid #4001C5;
  box-sizing: border-box;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
div[class^="MuiPickersBasePicker-container"] {
  padding-top: 8px;
}
div[class^="MuiPickersBasePicker-container"] div[class^="MuiPickersTimePickerToolbar"] {
  padding: 16px 0px;
  font-weight: 400;
  font-size: 60px;

  color: black;
}
div[class^="MuiPickersBasePicker-container"]  div[class*="MuiPickersToolbar-toolbar"] {
  background: #fff;
}
 div[class^="MuiPickersBasePicker-container"]  h2[class*="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiTypography-h2"]  {
 
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.7px;
background: rgba(0, 0, 0, 0.05);
border-radius: 3px;
color: #000000;
} 

.highlight-dynamic-list-item {
  animation: HIGHLIGHT 5s ease-out;
}

@keyframes HIGHLIGHT {
  0% {
    background: rgba(64, 1, 197, 0.2);
  }

  100% {
    background-color: #ffffff;
  }
}

.icon-wrapper {
  background: #000000;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 32px;
  font-size: 16px;
  position: "relative";
}

.icon-wrapper svg {
  fill: white;
  width: 16px;
  height: 16px;
}

.icon-wrapper.invisible {
  background: transparent;
}

.icon-wrapper.invisible svg {
  width: 32px;
  height: 32px;
}

.mapboxgl-popup {
  max-width: 300px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.active-marker {
  
}
.rc-swipeout {
  border-radius: 6px;
}

.rc-swipeout-btn {
  width: 64px;
  flex-wrap: wrap;
  white-space: normal;
  text-align: center;
}

.rc-swipeout-actions-right {
  top: 1px;
  bottom: 1px;
  right: 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.rc-swipeout-actions-left {
  top: 1px;
  bottom: 1px;
  left: 1px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.mobile-actions-edit {
  background: #20c763;
  background-image: url(/static/media/EditAction.f84ca123.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.mobile-actions-delete {
  background: #cc4a01;
  background-image: url(/static/media/delete-white.87660a85.svg);
  background-repeat: no-repeat;
  background-position: center center;
}

.mobile-actions-primary-actions {
  background: #4001c5;
  font-size: 12px;
  background-position: center center;
  color: #ffffff;
  line-height: 1.2;
}
.mobile-actions-duplicate {
  background: #cc4a01;
  font-size: 12px;
  background-position: center center;
  color: #ffffff;
  line-height: 1.2;
}
.mobile-actions-add-documents {
  background: #122044;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-view-documents {
  background: #444412;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-copy-link {
  background: rgba(18, 32, 68, 0.4);
  color: #ffffff;
  font-size: 12px;

  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-open-in-new-tab {
  background: #44121f;
  color: #ffffff;
  font-size: 12px;

  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-cancel {
  background: #122044;
  color: #ffffff;
  font-size: 12px;

  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-overtime {
  background: #0195c6;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.mobile-actions-resend-email {
  background: #441244;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.mobile-actions-copy-sms {
  background: rgba(18, 32, 68, 0.4);
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.mobile-send-driver-link {
  background: #444412;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}

.date-picker-wrapper .MuiPopover-paper {
  width: 361px;
  height: 361px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.date-picker-wrapper .MuiPickersBasePicker-pickerView {
  max-width: 100%;
  min-height: 100%;
  justify-content: flex-start;
}

.date-picker-wrapper .MuiPickersCalendarHeader-daysHeader,
.date-picker-wrapper .MuiPickersCalendar-week {
  justify-content: space-evenly;
}

.date-picker-wrapper .MuiPickersBasePicker-container {
  height: 100%;
}

.date-picker-wrapper .MuiPickersCalendar-transitionContainer {
  flex: 1 1;
}

.date-picker-wrapper .MuiPickersCalendar-transitionContainer > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.date-picker-wrapper .MuiPickersCalendarHeader-transitionContainer {
  width: auto;
}

.date-picker-wrapper
  .MuiPickersCalendarHeader-transitionContainer
  > p.MuiTypography-body1 {
  position: relative;
  line-height: initial;
}

.date-picker-wrapper .MuiPickersCalendarHeader-switchHeader {
  justify-content: center;
}

.date-picker-wrapper .MuiPickersDay-day:hover {
  background: rgba(64, 1, 197, 0.2);
}

.date-picker-wrapper
  .MuiPickersDay-day:hover
  span.MuiIconButton-label
  p.MuiTypography-body2 {
  font-weight: bold;
}

.date-picker-wrapper .MuiPickersDay-daySelected {
  background: #4001c5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.date-picker-wrapper
  button.MuiPickersDay-daySelected
  span.MuiIconButton-label
  p.MuiTypography-body2 {
  font-weight: bold !important;
}
.date-picker-wrapper-mobile .MuiPopover-paper {
  width: 100%;
}

.messages-list .str-chat > .str-chat__container {
  flex-direction: column;
}

.messages-list {
  width: 534px;
}

.str-chat__loading-channels {
  width: 100% !important;
}

.str-chat__channel-list-team .str-chat__channel-list-team__main {
  background-color: #ffffff;
}
.MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons.Mui-disabled{
  display: none;  
}
kendo-pdf-document .pdf-table-section {
  margin-bottom: 40px;
  border: 1px solid #ccc;
}

kendo-pdf-document .pdf-chart-inner {
  margin: 40px;
}

kendo-pdf-document .pdf-toolbar {
  min-height: 32px;
  height: 32px;
}

kendo-pdf-document .pdf-toolbar div {
  min-height: 32px;
  height: 32px;
}

kendo-pdf-document .pdf-main-container {
  margin-top: 80px;
  margin-bottom: 100px;
  padding-bottom: 100px;
}

kendo-pdf-document tfoot,
kendo-pdf-document .table-filters,
kendo-pdf-document .table-toolbar,
kendo-pdf-document #actions-column,
kendo-pdf-document .hide-on-export {
  display: none;
  position: absolute;
  bottom: -1000;
}

kendo-pdf-document .show-on-export {
  display: block;
}

.hide-on-web {
  display: none;
}

.show-on-web {
  display: block;
}

.counterRecordsMobile > div[class*="counterRecord"]::after {
  content: '';
  height: 20px; 
  width: 1px;
  position: absolute;
  right: -24px;
  top: 2px;
  background-color: #E6E6E6; 
}

.counterRecordsMobile > div[class*="counterRecord"]:last-child:after {
  background-color: transparent; 
}
.user-list{
   position: relative;
   display: inline;
}

.user-list:hover .user-projects{
   opacity: 1;
   visibility: visible;
  

}

.user-projects{
   position: absolute;
   background: #fff;
   z-index: 9;
   padding: 12px;
   width: 230px;
   transition: opacity ease 0.12s;
   box-shadow: 0px 0px 5px #ccc;
   overflow-y: auto;
   top: 8px;
   display: flex;
   flex-direction: column;
   max-height: 200px;
   opacity: 0;
   visibility: hidden;
   
}

.user-projects span{
   padding-bottom: 5px;
}

.slider-cards-highlight {
  position: relative;
}

.slider-cards-highlight::before,
.slider-cards-highlight::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

.slider-cards-highlight::before {
  border-width: 20px;
  border-right-color: #4001c5;
  border-top-color: #4001c5;
}

.slider-cards-highlight::after {
  font-family: "Material Icons";
  content: "\e5ca";
  color: #ffffff;
  border-radius: 0.4em;
  border-width: 4px;
  border-right-color: #4001c5;
  border-top-color: #4001c5;
}

#current-projects-carousel button {
  border: none;
}

.hd-project-selector-wrapper {
  display: inline-block;
  width: 100%;
}

.hd-project-selector-wrapper header {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
}

.hd-project-selector-wrapper h4 {
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  line-height: inherit;
}

.hd-project-selector-wrapper h4:last-child {
  font-weight: bold;
  margin-left: 4px;
}

.hd-project-selector-wrapper div[class*="project-selector__title"] {
  display: flex;
  align-items: center;
}

.hd-project-selector-wrapper div[class*="project-selector__action"] {
  padding: 0 0 0 8px;
}

.hd-project-selector-wrapper
  section[class*="expanded"]
  div[class*="project-selector__action"] {
  padding: 0 8px 0 0;
}

.hd-project-selector-wrapper div[class*="project-selector__action"] svg {
  fill: #000000;
}
.project-selector__title{
  margin-bottom: 16px;
}
.react-joyride__tooltip {
  background-color: #000000 !important;
  width: auto !important;
  padding: 0 !important;
}

.react-joyride__tooltip button {
  padding: 0 !important;
}

.react-joyride__tooltip > button > svg path {
  fill: #ffffff;
}

.react-joyride__tooltip [data-action="skip"],
.react-joyride__tooltip [data-action="back"],
.react-joyride__tooltip [data-action="primary"],
.react-joyride__tooltip button:focus {
  outline: none !important;
  background-color: transparent !important;
  color: #ffffff !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 0.3px !important;
  margin-left: unset !important;
}

.react-joyride__tooltip + .__floater__arrow svg polygon {
  fill: #000000;
}

.react-joyride__tooltip > div:nth-child(1) > div {
  padding: 16px !important;
}

.react-joyride__tooltip [data-action="close"] {
  padding: 0 !important;
  top: 16px !important;
  right: 16px !important;
}

.react-joyride__tooltip [data-action="close"] > svg {
  height: 8px;
  width: 8px;
}

.react-joyride__tooltip > div:nth-child(2) {
  padding: 20px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  justify-content: space-between !important;
}

.react-joyride__tooltip > div:nth-child(2) > div {
  display: none;
}

.react-joyride__tooltip [data-action="primary"]:after {
  content: "";
  background: url(/static/media/chevron_right_white.25d8d114.svg) no-repeat;
  padding: 0 0 0 14px;
  background-position: right;
}

.react-joyride__tooltip [data-action="back"]:before {
  content: "";
  background: url(/static/media/chevron_left_white.201ad243.svg) no-repeat;
  padding: 0 14px 0 0;
  background-position: left;
}

#react-joyride-step-0 .react-joyride__tooltip > div:nth-child(2) > div {
  display: unset;
}

#react-joyride-step-0 .react-joyride__tooltip [data-action="primary"]:after {
  background: none;
  padding: 0;
}

.car {
  margin: 0 auto;
  position: relative;
  width: 200px;
  animation: moving 4s linear -2s infinite;
}
.car:before {
  content: "";
  display: block;
  animation: carmove 3.1s infinite linear;
  background: url("https://i.stack.imgur.com/xWNOG.png") center/cover;
  padding-top: 45.25%;
}

.weel {
  animation: wheel 10s infinite -2s linear;
  background: url("https://i.stack.imgur.com/0Osjx.png") center/cover;
  position: absolute;
  bottom: 0.8%;
  width: 14.15%;
}
.weel:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.weel1 {
  left: 14.5%;
}
.weel2 {
  right: 10%;
}

/*animations*/
@keyframes carmove {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(1px);
  }
  29% {
    transform: translateY(2px);
  }
  33% {
    transform: translateY(3px);
  }
  47% {
    transform: translateY(0px);
  }
  58% {
    transform: translateY(1px);
  }
  62% {
    transform: translateY(2px);
  }
  66% {
    transform: translateY(3px);
  }
  75% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes wheel {
  0% {
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(-920deg);
  }
  36%,
  56% {
    transform: rotate(-920deg);
  }
  100% {
    transform: rotate(-1440deg);
  }
}

@keyframes moving {
  0% {
    right: -80em;
    animation-timing-function: ease-out;
  }
  35% {
    right: 0;
  }
  36%,
  56% {
    right: 0;
    animation-timing-function: ease-in;
  }
  100% {
    right: 80em;
  }
}

.str-chat__channel-list-team__header {
  opacity: 0;
}

.str-chat__input--textarea-wrapper .str-chat__textarea textarea {
  background: #ffffff;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #959393;
}

.str-chat__input--textarea-wrapper .str-chat__textarea textarea:active,
.str-chat__input--textarea-wrapper .str-chat__textarea textarea:hover {
  background: #ffffff;
  /* Primary / 100% */

  border: 1px solid #4001c5;
  box-sizing: border-box;
  border-radius: 3px;
}

.str-chat__channel-preview--active {
  background: #4001c5;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;

  color: #ffffff;
}

.str-chat__channel-preview-title {
  font-weight: bold;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}

div [class^="MuiPaper-root"] span[class*="MuiTypography-colorTextSecondary"] {
  color: #000000;
}
div [class^="MuiPaper-root"] span[class*="MuiTypography-overline"] {
  text-transform: capitalize;
}
div [class^="MuiDialogActions-root"] button[class*="MuiButton-text"] {
  font-size: 14px;
}

.Cal__Day__root.Cal__Day__today {
  color: transparent !important;
  background: rgba(64, 1, 197, 0.2);
  border-radius: 800px;
}

.Cal__Day__root.Cal__Day__today > span {
  color: #000;
  font-weight: bold;
  font-family: "Roboto";
}

.Cal__Day__root,
.Cal__Weekdays__day,
.Cal__Today__root.Cal__Today__show {
  font-family: "Roboto";
  color: #333;
}
.Cal__Today__root.Cal__Today__show,
.Cal__Today__root {
  background-color: rgb(64 3 197) !important;
}
.Cal__Weekdays__root,
.Cal__Header__root {
  background-color: #fff !important;
}
.Cal__Header__root {
  display: none;
}
.Cal__Header__wrapper.Cal__Header__blank {
  color: #fff;
  height: 28px;
  line-height: 28px;
  font-family: "Roboto";
  text-align: center;
}
.Cal__Month__rows,
.Cal__Month__label {
  background: #fff;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  background: rgba(64, 1, 197, 1) !important;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__enabled::before {
  background: transparent !important;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__enabled:hover {
  background: rgba(64, 1, 197, 0.4) !important;
}
.Cal__Day__root.Cal__Day__enabled::before {
  background: rgba(64, 1, 197, 0.8) !important;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__month {
  display: none;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  position: relative;
  top: -1px;
}
/* .Cal__Month__row.Cal__Month__partial:first-child li:first-child,
.Cal__Month__row:first-child li {
  box-shadow: none;
} */
@media (max-width: 767px) {
  .Cal__Container__root {
    width: 100% !important;
  }
}

