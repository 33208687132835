.date-picker-wrapper .MuiPopover-paper {
  width: 361px;
  height: 361px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.date-picker-wrapper .MuiPickersBasePicker-pickerView {
  max-width: 100%;
  min-height: 100%;
  justify-content: flex-start;
}

.date-picker-wrapper .MuiPickersCalendarHeader-daysHeader,
.date-picker-wrapper .MuiPickersCalendar-week {
  justify-content: space-evenly;
}

.date-picker-wrapper .MuiPickersBasePicker-container {
  height: 100%;
}

.date-picker-wrapper .MuiPickersCalendar-transitionContainer {
  flex: 1;
}

.date-picker-wrapper .MuiPickersCalendar-transitionContainer > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.date-picker-wrapper .MuiPickersCalendarHeader-transitionContainer {
  width: auto;
}

.date-picker-wrapper
  .MuiPickersCalendarHeader-transitionContainer
  > p.MuiTypography-body1 {
  position: relative;
  line-height: initial;
}

.date-picker-wrapper .MuiPickersCalendarHeader-switchHeader {
  justify-content: center;
}

.date-picker-wrapper .MuiPickersDay-day:hover {
  background: rgba(64, 1, 197, 0.2);
}

.date-picker-wrapper
  .MuiPickersDay-day:hover
  span.MuiIconButton-label
  p.MuiTypography-body2 {
  font-weight: bold;
}

.date-picker-wrapper .MuiPickersDay-daySelected {
  background: #4001c5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.date-picker-wrapper
  button.MuiPickersDay-daySelected
  span.MuiIconButton-label
  p.MuiTypography-body2 {
  font-weight: bold !important;
}
.date-picker-wrapper-mobile .MuiPopover-paper {
  width: 100%;
}
