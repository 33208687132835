.counterRecordsMobile > div[class*="counterRecord"]::after {
  content: '';
  height: 20px; 
  width: 1px;
  position: absolute;
  right: -24px;
  top: 2px;
  background-color: #E6E6E6; 
}

.counterRecordsMobile > div[class*="counterRecord"]:last-child:after {
  background-color: transparent; 
}