
div [class^="MuiPickersCalendar-week"] button[class*="MuiPickersDay-daySelected"] 
{
    background: #4001C5;

}
div [class^="MuiPickersCalendar-week"] button[class*="MuiPickersDay-daySelected"]:hover 
{
    background: #4001C5;

}
  div [class^="MuiPickersCalendarHeader-switchHeader"] p[class*=" MuiTypography-body1"]{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    
    text-align: center;
  
    
    color: #3F3B3B;
}
div[class^="MuiPickersCalendarHeader-daysHeader"] span[class *="MuiPickersCalendarHeader-dayLabel"]{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #3F3B3B;
}
div [class^="MuiPickersCalendar-week"] button[class*="MuiPickersDay-day"] 
span[class *="MuiIconButton-label"] p[class*="MuiTypography-body2"]{
    position: absolute;
    height: 24px;
    left: 0%;
    right: 0%;
    top: calc(50% - 24px/2 + 1px);
    
    /* Caption/14px/Regular */
    
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    
    text-align: center;
    
    /* Black/700 (Base) */
    
      
    }
