.messages-list .str-chat > .str-chat__container {
  flex-direction: column;
}

.messages-list {
  width: 534px;
}

.str-chat__loading-channels {
  width: 100% !important;
}

.str-chat__channel-list-team .str-chat__channel-list-team__main {
  background-color: #ffffff;
}