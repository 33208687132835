.user-list{
   position: relative;
   display: inline;
}

.user-list:hover .user-projects{
   opacity: 1;
   visibility: visible;
  

}

.user-projects{
   position: absolute;
   background: #fff;
   z-index: 9;
   padding: 12px;
   width: 230px;
   transition: opacity ease 0.12s;
   box-shadow: 0px 0px 5px #ccc;
   overflow-y: auto;
   top: 8px;
   display: flex;
   flex-direction: column;
   max-height: 200px;
   opacity: 0;
   visibility: hidden;
   
}

.user-projects span{
   padding-bottom: 5px;
}
