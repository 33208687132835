.icon-wrapper {
  background: #000000;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 32px;
  font-size: 16px;
  position: "relative";
}

.icon-wrapper svg {
  fill: white;
  width: 16px;
  height: 16px;
}

.icon-wrapper.invisible {
  background: transparent;
}

.icon-wrapper.invisible svg {
  width: 32px;
  height: 32px;
}

.mapboxgl-popup {
  max-width: 300px;
}
