div [class^="MuiPaper-root"] span[class*="MuiTypography-colorTextSecondary"] {
  color: #000000;
}
div [class^="MuiPaper-root"] span[class*="MuiTypography-overline"] {
  text-transform: capitalize;
}
div [class^="MuiDialogActions-root"] button[class*="MuiButton-text"] {
  font-size: 14px;
}

.Cal__Day__root.Cal__Day__today {
  color: transparent !important;
  background: rgba(64, 1, 197, 0.2);
  border-radius: 800px;
}

.Cal__Day__root.Cal__Day__today > span {
  color: #000;
  font-weight: bold;
  font-family: "Roboto";
}

.Cal__Day__root,
.Cal__Weekdays__day,
.Cal__Today__root.Cal__Today__show {
  font-family: "Roboto";
  color: #333;
}
.Cal__Today__root.Cal__Today__show,
.Cal__Today__root {
  background-color: rgb(64 3 197) !important;
}
.Cal__Weekdays__root,
.Cal__Header__root {
  background-color: #fff !important;
}
.Cal__Header__root {
  display: none;
}
.Cal__Header__wrapper.Cal__Header__blank {
  color: #fff;
  height: 28px;
  line-height: 28px;
  font-family: "Roboto";
  text-align: center;
}
.Cal__Month__rows,
.Cal__Month__label {
  background: #fff;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  background: rgba(64, 1, 197, 1) !important;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__enabled::before {
  background: transparent !important;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__enabled:hover {
  background: rgba(64, 1, 197, 0.4) !important;
}
.Cal__Day__root.Cal__Day__enabled::before {
  background: rgba(64, 1, 197, 0.8) !important;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__month {
  display: none;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  position: relative;
  top: -1px;
}
/* .Cal__Month__row.Cal__Month__partial:first-child li:first-child,
.Cal__Month__row:first-child li {
  box-shadow: none;
} */
@media (max-width: 767px) {
  .Cal__Container__root {
    width: 100% !important;
  }
}
