.str-chat__channel-list-team__header {
  opacity: 0;
}

.str-chat__input--textarea-wrapper .str-chat__textarea textarea {
  background: #ffffff;
  border: 1px solid #b9b9b9;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #959393;
}

.str-chat__input--textarea-wrapper .str-chat__textarea textarea:active,
.str-chat__input--textarea-wrapper .str-chat__textarea textarea:hover {
  background: #ffffff;
  /* Primary / 100% */

  border: 1px solid #4001c5;
  box-sizing: border-box;
  border-radius: 3px;
}

.str-chat__channel-preview--active {
  background: #4001c5;
  color: white;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.3px;

  color: #ffffff;
}

.str-chat__channel-preview-title {
  font-weight: bold;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
