.react-joyride__tooltip {
  background-color: #000000 !important;
  width: auto !important;
  padding: 0 !important;
}

.react-joyride__tooltip button {
  padding: 0 !important;
}

.react-joyride__tooltip > button > svg path {
  fill: #ffffff;
}

.react-joyride__tooltip [data-action="skip"],
.react-joyride__tooltip [data-action="back"],
.react-joyride__tooltip [data-action="primary"],
.react-joyride__tooltip button:focus {
  outline: none !important;
  background-color: transparent !important;
  color: #ffffff !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  font-size: 12px !important;
  letter-spacing: 0.3px !important;
  margin-left: unset !important;
}

.react-joyride__tooltip + .__floater__arrow svg polygon {
  fill: #000000;
}

.react-joyride__tooltip > div:nth-child(1) > div {
  padding: 16px !important;
}

.react-joyride__tooltip [data-action="close"] {
  padding: 0 !important;
  top: 16px !important;
  right: 16px !important;
}

.react-joyride__tooltip [data-action="close"] > svg {
  height: 8px;
  width: 8px;
}

.react-joyride__tooltip > div:nth-child(2) {
  padding: 20px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  justify-content: space-between !important;
}

.react-joyride__tooltip > div:nth-child(2) > div {
  display: none;
}

.react-joyride__tooltip [data-action="primary"]:after {
  content: "";
  background: url(../Icons/Functional/Arrows/chevron_right_white.svg) no-repeat;
  padding: 0 0 0 14px;
  background-position: right;
}

.react-joyride__tooltip [data-action="back"]:before {
  content: "";
  background: url(../Icons/Functional/Arrows/chevron_left_white.svg) no-repeat;
  padding: 0 14px 0 0;
  background-position: left;
}

#react-joyride-step-0 .react-joyride__tooltip > div:nth-child(2) > div {
  display: unset;
}

#react-joyride-step-0 .react-joyride__tooltip [data-action="primary"]:after {
  background: none;
  padding: 0;
}
