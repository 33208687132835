.highlight-dynamic-list-item {
  animation: HIGHLIGHT 5s ease-out;
}

@keyframes HIGHLIGHT {
  0% {
    background: rgba(64, 1, 197, 0.2);
  }

  100% {
    background-color: #ffffff;
  }
}
