.hd-project-selector-wrapper {
  display: inline-block;
  width: 100%;
}

.hd-project-selector-wrapper header {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
}

.hd-project-selector-wrapper h4 {
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  line-height: inherit;
}

.hd-project-selector-wrapper h4:last-child {
  font-weight: bold;
  margin-left: 4px;
}

.hd-project-selector-wrapper div[class*="project-selector__title"] {
  display: flex;
  align-items: center;
}

.hd-project-selector-wrapper div[class*="project-selector__action"] {
  padding: 0 0 0 8px;
}

.hd-project-selector-wrapper
  section[class*="expanded"]
  div[class*="project-selector__action"] {
  padding: 0 8px 0 0;
}

.hd-project-selector-wrapper div[class*="project-selector__action"] svg {
  fill: #000000;
}
.project-selector__title{
  margin-bottom: 16px;
}