.car {
  margin: 0 auto;
  position: relative;
  width: 200px;
  animation: moving 4s linear -2s infinite;
}
.car:before {
  content: "";
  display: block;
  animation: carmove 3.1s infinite linear;
  background: url("https://i.stack.imgur.com/xWNOG.png") center/cover;
  padding-top: 45.25%;
}

.weel {
  animation: wheel 10s infinite -2s linear;
  background: url("https://i.stack.imgur.com/0Osjx.png") center/cover;
  position: absolute;
  bottom: 0.8%;
  width: 14.15%;
}
.weel:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.weel1 {
  left: 14.5%;
}
.weel2 {
  right: 10%;
}

/*animations*/
@keyframes carmove {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(1px);
  }
  29% {
    transform: translateY(2px);
  }
  33% {
    transform: translateY(3px);
  }
  47% {
    transform: translateY(0px);
  }
  58% {
    transform: translateY(1px);
  }
  62% {
    transform: translateY(2px);
  }
  66% {
    transform: translateY(3px);
  }
  75% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes wheel {
  0% {
    transform: rotate(0deg);
  }
  35% {
    transform: rotate(-920deg);
  }
  36%,
  56% {
    transform: rotate(-920deg);
  }
  100% {
    transform: rotate(-1440deg);
  }
}

@keyframes moving {
  0% {
    right: -80em;
    animation-timing-function: ease-out;
  }
  35% {
    right: 0;
  }
  36%,
  56% {
    right: 0;
    animation-timing-function: ease-in;
  }
  100% {
    right: 80em;
  }
}
