kendo-pdf-document .pdf-table-section {
  margin-bottom: 40px;
  border: 1px solid #ccc;
}

kendo-pdf-document .pdf-chart-inner {
  margin: 40px;
}

kendo-pdf-document .pdf-toolbar {
  min-height: 32px;
  height: 32px;
}

kendo-pdf-document .pdf-toolbar div {
  min-height: 32px;
  height: 32px;
}

kendo-pdf-document .pdf-main-container {
  margin-top: 80px;
  margin-bottom: 100px;
  padding-bottom: 100px;
}

kendo-pdf-document tfoot,
kendo-pdf-document .table-filters,
kendo-pdf-document .table-toolbar,
kendo-pdf-document #actions-column,
kendo-pdf-document .hide-on-export {
  display: none;
  position: absolute;
  bottom: -1000;
}

kendo-pdf-document .show-on-export {
  display: block;
}

.hide-on-web {
  display: none;
}

.show-on-web {
  display: block;
}
