html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* padding-right: 0 !important; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    96.34deg,
    #fcfcfc 8.92%,
    #fcfcfc 8.93%,
    #f8faff 100%
  );
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blurry-bg {
  filter: blur(5px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  display: none;
  z-index: 10;
}
