.rc-swipeout {
  border-radius: 6px;
}

.rc-swipeout-btn {
  width: 64px;
  flex-wrap: wrap;
  white-space: normal;
  text-align: center;
}

.rc-swipeout-actions-right {
  top: 1px;
  bottom: 1px;
  right: 1px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.rc-swipeout-actions-left {
  top: 1px;
  bottom: 1px;
  left: 1px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.mobile-actions-edit {
  background: #20c763;
  background-image: url("../Icons/mobile-action-icons/EditAction.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.mobile-actions-delete {
  background: #cc4a01;
  background-image: url("../Icons/Functional/delete-white.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.mobile-actions-primary-actions {
  background: #4001c5;
  font-size: 12px;
  background-position: center center;
  color: #ffffff;
  line-height: 1.2;
}
.mobile-actions-duplicate {
  background: #cc4a01;
  font-size: 12px;
  background-position: center center;
  color: #ffffff;
  line-height: 1.2;
}
.mobile-actions-add-documents {
  background: #122044;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-view-documents {
  background: #444412;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-copy-link {
  background: rgba(18, 32, 68, 0.4);
  color: #ffffff;
  font-size: 12px;

  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-open-in-new-tab {
  background: #44121f;
  color: #ffffff;
  font-size: 12px;

  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-cancel {
  background: #122044;
  color: #ffffff;
  font-size: 12px;

  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1.2;
}
.mobile-actions-overtime {
  background: #0195c6;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.mobile-actions-resend-email {
  background: #441244;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.mobile-actions-copy-sms {
  background: rgba(18, 32, 68, 0.4);
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
.mobile-send-driver-link {
  background: #444412;
  color: #ffffff;
  font-size: 12px;
  background-repeat: no-repeat;
  background-position: center center;
}
