.slider-cards-highlight {
  position: relative;
}

.slider-cards-highlight::before,
.slider-cards-highlight::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

.slider-cards-highlight::before {
  border-width: 20px;
  border-right-color: #4001c5;
  border-top-color: #4001c5;
}

.slider-cards-highlight::after {
  font-family: "Material Icons";
  content: "\e5ca";
  color: #ffffff;
  border-radius: 0.4em;
  border-width: 4px;
  border-right-color: #4001c5;
  border-top-color: #4001c5;
}
