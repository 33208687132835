
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersToolbar"] {
  background: transparent;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersToolbarText-toolbarTxt"] {
  color: black;
  padding: 8px;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClock-container"] {
  margin: 0px;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClock-pin"] {
  background: #4001c5;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClockPointer-pointer"] {
  background: #4001c5;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClockPointer-thumb"] {
  border: 14px solid #4001c5;
}
div[class^="MuiPickersBasePicker-container"]  div [class*="MuiPickersClockPointer-noPoint"] {
  background: #4001c5;
}

div[class^="MuiPickersTimePickerToolbar"] h2[class*="MuiPickersToolbarText-toolbarBtnSelected"] {
  background: #fff;
  font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;

display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.7px;
   border: 1px solid #4001C5;
  box-sizing: border-box;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
}
div[class^="MuiPickersBasePicker-container"] {
  padding-top: 8px;
}
div[class^="MuiPickersBasePicker-container"] div[class^="MuiPickersTimePickerToolbar"] {
  padding: 16px 0px;
  font-weight: 400;
  font-size: 60px;

  color: black;
}
div[class^="MuiPickersBasePicker-container"]  div[class*="MuiPickersToolbar-toolbar"] {
  background: #fff;
}
 div[class^="MuiPickersBasePicker-container"]  h2[class*="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiTypography-h2"]  {
 
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: -0.7px;
background: rgba(0, 0, 0, 0.05);
border-radius: 3px;
color: #000000;
} 
